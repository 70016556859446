/**
 * External dependencies
 */
import React, { Fragment } from 'react';

/**
 * Internal dependencies
 */
import {
	Instructions,
	Label,
	Name,
	Choices,
} from '../helpers/fields';

function Radio( { name } ) {
	return (
		<Fragment>
			<Label name={ name } />
			<Name name={ name } />
			<Instructions name={ name } />
			<Choices name={ name } />
		</Fragment>
	);
}

export default Radio;
