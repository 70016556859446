// @flow

/**
 * External dependencies
 */
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

/**
 * Internal dependencies
 */
import { Label, Input } from './helpers/table';
import * as types from './types';

/**
 * Stylesheets
 */
import './style.css';

class Fields extends PureComponent {
	render() {
		const { type, name } = this.props;

		if ( types[ type ] ) {
			return React.createElement( types[ type ], {
				name,
			} );
		}

		return (
			<tr>
				<Label />
				<Input>Missing fields - { type }</Input>
			</tr>
		);
	}
}

class TypeFields extends PureComponent {
	render() {
		const { type, name, index, fields, total } = this.props;
		return (
			<div className={ `fcc-wrap field-type-${ type }` }>
				<div className="fcc-header">
					{ index + 1 }
					<div className="fcc-header-actions">
						{ index !== 0 && (
							<button type="button" onClick={ () => fields.swap( index, index - 1 ) }>
								<FontAwesomeIcon icon={ faCaretUp } />
							</button>
						) }
						{ total !== index + 1 && (
							<button type="button" onClick={ () => fields.swap( index, index + 1 ) }>
								<FontAwesomeIcon icon={ faCaretDown } />
							</button>
						) }
						<button type="button" onClick={ () => fields.remove( index ) }>
							<FontAwesomeIcon icon={ faTrashAlt } />
						</button>
					</div>
				</div>
				<table>
					<tbody>
						<tr>
							<Label>Field Type</Label>
							<Input>
								<Field name={ `${ name }.type` } component="select">
									<optgroup label="Basic">
										<option value="text">Text</option>
										<option value="textarea">Textarea</option>
										<option value="number">Number</option>
										<option value="email">Email</option>
									</optgroup>
									<optgroup label="Options">
										<option value="radio">Radio</option>
									</optgroup>
									<optgroup label="Setup">
										<option value="infotext">Message</option>
										<option value="fieldset">Fieldset</option>
										<option value="page">Page</option>
										<option value="section">Section</option>
										<option value="summary">Summary</option>
									</optgroup>
								</Field>
							</Input>
						</tr>
						<Fields type={ type } name={ name } />
					</tbody>
				</table>
			</div>
		);
	}
}

const selector = formValueSelector( 'form-builder' );
export default connect( ( state, props ) => {
	return {
		type: selector( state, `${ props.name }.type` ),
	};
} )( TypeFields );
