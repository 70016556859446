// @flow

/**
 * External dependencies
 */
import { createStore, combineReducers, compose } from 'redux';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Internal dependencies
 */
import App from './App';
import './style.css';
import './style/reset.css';
import './style/fonts.css';
import loaderReducer from './components/load/reducer';

const rootReducer = combineReducers( {
	form: formReducer,
	loader: loaderReducer,
} );

const composeEnhancers = ( 'production' !== process.env.NODE_ENV && 'undefined' !== typeof window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) || compose;
const store = createStore( rootReducer, {}, composeEnhancers() );
const rootElement = document.getElementById( 'root' );

if ( rootElement ) {
	ReactDOM.render( (
		<Provider store={ store }>
			<App />
		</Provider>
	), rootElement );
}
