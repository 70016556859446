/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import { Label } from '../helpers/fields';

function Section( { name } ) {
	return (
		<Label label="Title" name={ name } />
	);
}

export default Section;
