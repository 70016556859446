/**
 * External dependencies
 */
import React, { PureComponent } from 'react';

export class Label extends PureComponent {
	render() {
		const { children, title, instructions } = this.props;

		return (
			<td className="fcc-label">
				{ title }
				{ children }
				{ instructions && (
					<div className="fcc-label-instructions">{ instructions }</div>
				) }
			</td>
		);
	}
}

export class Input extends PureComponent {
	render() {
		const { children } = this.props;

		return (
			<td className="fcc-input">
				{ children }
			</td>
		);
	}
}
