/**
 * External dependencies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * Internal dependencies
 */
import { load as loadDefault } from './reducer';

/**
 * Stylesheets
 */
import './style.css';

class Load extends Component {
	state = {
		text: '',
		error: '',
	};

	handleSubmit = ( event ) => {
		event.preventDefault();

		try {
			const data = JSON.parse( this.state.text );
			this.props.load( data );
			this.props.cancel();
		} catch ( e ) {
			this.setState( { error: 'Not a valid json config' } );
			return false;
		}
	};

	render() {
		let classes = 'fcc-load';

		if ( this.state.error ) {
			classes += ' fcc-load-has-error';
		}

		return (
			<div className={ classes }>
				<form onSubmit={ this.handleSubmit } className="fcc-load-form">
					<textarea
						value={ this.state.text }
						onChange={ ( event ) => {
							this.setState( { text: event.target.value } );
						} }
						placeholder="Paste in JSON config here..."
					/>

					{ this.state.error && (
						<div className="fcc-load-error">{ this.state.error }</div>
					) }

					<div className="fcc-load-footer">
						<button type="button" className="fcc-load-cancel" onClick={ this.props.cancel }>Cancel</button>
						<button type="submit" className="fcc-load-submit">Load</button>
					</div>
				</form>
			</div>
		);
	}
}

export default connect(
	() => ( {} ),
	{ load: loadDefault },
)( Load );
