/**
 * External dependencies
 */
import { Field, reduxForm, FieldArray } from 'redux-form';
import React from 'react';
import { pick } from 'lodash';
import { connect } from 'react-redux';

/**
 * Internal dependencies
 */
import FormFields from './components/fields';
import Load from './components/load';

class ContactPage extends React.Component {
	state = {
		json: '',
		showInit: false,
	}

	submit = ( values ) => {
		const fields = ! values.fields ? [] : values.fields.map( ( field ) => {
			if ( field.type === 'page' ) {
				return pick( field, [ 'type', 'label', 'hideFromSummary' ] );
			}

			return field;
		} );

		const finalValues = {
			...values,
			fields,
		};

		this.setState( { json: JSON.stringify( finalValues, null, 2 ) } );
	}

	hideInit = () => {
		this.setState( { showInit: false } );
	}

	render() {
		return (
			<div className="fcc-form">
				{ this.state.showInit && (
					<Load cancel={ this.hideInit } />
				) }
				<form onSubmit={ this.props.handleSubmit( this.submit ) }>
					<div>
						<label htmlFor="key">Key</label>
						<Field name="key" component="input" type="text" id="key" required />
					</div>

					<FieldArray name="fields" component={ FormFields } />

					<div>
						<label htmlFor="nextPageLabel">Next page label</label>
						<Field name="nextPageLabel" component="input" type="text" id="nextPageLabel" />
					</div>
					<div>
						<label htmlFor="submitLabel">Submit label</label>
						<Field name="submitLabel" component="input" type="text" id="submitLabel" />
					</div>
					<button className="fcc-load-submit" type="button" style={ { marginLeft: 0 } } onClick={ () => this.setState( { showInit: true } ) }>Load config</button>
					<button className="fcc-load-submit" type="submit">Generate config</button>
				</form>
				{ this.state.json && (
					<pre className="fcc-json">{ this.state.json }</pre>
				) }
			</div>
		);
	}
}

export default connect(
	state => ( {
		initialValues: state.loader.data,
	} ),
)( reduxForm( {
	form: 'form-builder',
} )( ContactPage ) );
