/**
 * External dependencies
 */
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { isObject } from 'lodash';

/**
 * Internal dependencies
 */
import { Label as TableLabel, Input } from './table';
import { convertToNumber, convertChoicesToHuman, convertChoicesToData } from './normalize';

export class Choices extends PureComponent {
	static defaultProps = {
		required: true,
	}

	field = ( opts ) => {
		const { value, ...otherInputProps } = opts.input;
		let defaultValue = value;

		if ( isObject( value ) ) {
			defaultValue = convertChoicesToHuman( value );
		}

		return (
			<textarea
				rows="9"
				defaultValue={ defaultValue }
				{ ...otherInputProps }
			/>
		);
	}

	render() {
		const { name, required } = this.props;

		return (
			<tr>
				<TableLabel
					title="Choices"
					instructions="Enter each choice on a new line. For more control, you may specify both a value and label like this: red : Red"
				/>
				<Input>
					<Field
						component={ this.field }
						name={ `${ name }.choices` }
						required={ required }
						rows="9"
						normalize={ convertChoicesToData }
					/>
				</Input>
			</tr>
		);
	}
}

export class Instructions extends PureComponent {
	render() {
		const { name } = this.props;

		return (
			<tr>
				<TableLabel
					title="Instructions"
					instructions="Instructions for authors. Shown when below input"
				/>
				<Input>
					<Field
						component="input"
						name={ `${ name }.instructions` }
						type="text"
					/>
				</Input>
			</tr>
		);
	}
}

export class Label extends PureComponent {
	static defaultProps = {
		label: 'Field Label',
		required: true,
	}

	render() {
		const { label, name, required } = this.props;

		return (
			<tr>
				<TableLabel
					title={ label }
					instructions="This is the name which will appear above the input"
				/>
				<Input>
					<Field
						component="input"
						name={ `${ name }.label` }
						required={ required }
						type="text"
					/>
				</Input>
			</tr>
		);
	}
}

export class MaxLength extends PureComponent {
	render() {
		const { name } = this.props;

		return (
			<tr>
				<TableLabel
					title="Character Limit"
					instructions="Leave blank for no limit"
				/>
				<Input>
					<Field
						component="input"
						name={ `${ name }.maxlength` }
						normalize={ convertToNumber }
						type="number"
						min="1"
					/>
				</Input>
			</tr>
		);
	}
}

export class Name extends PureComponent {
	static defaultProps = {
		required: true,
	}

	render() {
		const { name, required } = this.props;

		return (
			<tr>
				<TableLabel
					title="Field Name"
					instructions="Single word, no spaces. Underscores and dashes allowed"
				/>
				<Input>
					<Field
						component="input"
						name={ `${ name }.name` }
						required={ required }
						type="text"
					/>
				</Input>
			</tr>
		);
	}
}

export class Placeholder extends PureComponent {
	render() {
		const { name } = this.props;

		return (
			<tr>
				<TableLabel
					instructions="Appears within the input"
					title="Placeholder Text"
				/>
				<Input>
					<Field
						component="input"
						name={ `${ name }.placeholder` }
						type="text"
					/>
				</Input>
			</tr>
		);
	}
}

export class Wrapper extends PureComponent {
	render() {
		const { name } = this.props;

		return (
			<tr>
				<TableLabel
					title="Wrapper Attributes"
				/>
				<Input>
					<div className="fcc-input-flex">
						<div>
							<label>Wrapper Size</label>
							<Field
								component="select"
								name={ `${ name }.size` }
							>
								<option value="" />
								<option value="small">Small</option>
								<option value="large">Large</option>
							</Field>
						</div>
						<div>
							<label>Field Size</label>
							<Field
								component="select"
								name={ `${ name }.innerSize` }
							>
								<option value="" />
								<option value="small">Small</option>
								<option value="large">Large</option>
							</Field>
						</div>
					</div>
				</Input>
			</tr>
		);
	}
}
