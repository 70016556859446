/**
 * External dependencies
 */
import React, { Fragment } from 'react';
import { Field, FieldArray } from 'redux-form';

/**
 * Internal dependencies
 */
import { Label, Input } from '../helpers/table';
import FormFields from '..';

function Fieldset( { name } ) {
	return (
		<Fragment>
			<tr>
				<Label>Label</Label>
				<Input>
					<Field name={ `${ name }.label` } component="input" type="text" required />
				</Input>
			</tr>
			<tr>
				<Label>Subfields</Label>
				<Input>
					<FieldArray name={ `${ name }.subFields` } component={ FormFields } />
				</Input>
			</tr>
		</Fragment>
	);
}

export default Fieldset;
