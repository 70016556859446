/**
 * External dependencies
 */
import React, { Fragment } from 'react';
import { Field } from 'redux-form';

/**
 * Internal dependencies
 */
import {
	Instructions,
	Label,
	MaxLength,
	Name,
	Placeholder,
} from '../helpers/fields';
import { Label as TableLabel, Input } from '../helpers/table';
import { convertToNumber } from '../helpers/normalize';

function Textarea( { name } ) {
	return (
		<Fragment>
			<Label name={ name } />
			<Name name={ name } />
			<Placeholder name={ name } />
			<Instructions name={ name } />
			<MaxLength name={ name } />
			<tr>
				<TableLabel
					title="Rows"
					instructions="Sets the textarea height"
				/>
				<Input>
					<Field
						component="input"
						name={ `${ name }.minRows` }
						type="number"
						normalize={ convertToNumber }
					/>
				</Input>
			</tr>
		</Fragment>
	);
}

export default Textarea;
