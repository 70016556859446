/**
 * External dependencies
 */
import React, { Fragment } from 'react';
import { Field } from 'redux-form';

/**
 * Internal dependencies
 */
import { Label } from '../helpers/fields';
import { Label as TableLabel, Input } from '../helpers/table';
import { removeIfFalse } from '../helpers/normalize';

function Page( { name } ) {
	return (
		<Fragment>
			<Label label="Title" name={ name } />
			<tr>
				<TableLabel title="Hide in summary" />
				<Input>
					<Field
						name={ `${ name }.hideFromSummary` }
						component="input"
						type="checkbox"
						normalize={ removeIfFalse }
					/>
				</Input>
			</tr>
		</Fragment>
	);
}

export default Page;
