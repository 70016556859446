/**
 * External dependencies
 */
import React, { Fragment } from 'react';
import { Field } from 'redux-form';

/**
 * Internal dependencies
 */
import {
	Instructions,
	Label,
	Name,
	Wrapper,
} from '../helpers/fields';
import { Label as TableLabel, Input } from '../helpers/table';
import { convertToNumber } from '../helpers/normalize';

function Number( { name } ) {
	return (
		<Fragment>
			<Label name={ name } />
			<Name name={ name } />
			<Instructions name={ name } />
			<tr>
				<TableLabel title="Minimum Value" />
				<Input>
					<Field
						component="input"
						name={ `${ name }.min` }
						type="number"
						normalize={ convertToNumber }
					/>
				</Input>
			</tr>
			<tr>
				<TableLabel title="Maximum Value" />
				<Input>
					<Field
						component="input"
						name={ `${ name }.max` }
						type="number"
						normalize={ convertToNumber }
					/>
				</Input>
			</tr>
			<tr>
				<TableLabel title="Step Size" />
				<Input>
					<Field
						component="input"
						name={ `${ name }.step` }
						type="number"
						normalize={ convertToNumber }
						step="any"
					/>
				</Input>
			</tr>
			<Wrapper name={ name } />
			<tr>
				<TableLabel title="Percent validator" />
				<Input>
					<div className="fcc-input-flex fcc-input-flex-3">
						<div>
							<label>Min</label>
							<Field
								component="input"
								name={ `${ name }.percentValidator.min` }
								normalize={ convertToNumber }
								type="number"
							/>
						</div>
						<div>
							<label>Decrease</label>
							<Field
								component="input"
								name={ `${ name }.percentValidator.decrease` }
								normalize={ convertToNumber }
								type="number"
							/>
						</div>
						<div>
							<label>Increase</label>
							<Field
								component="input"
								name={ `${ name }.percentValidator.increase` }
								normalize={ convertToNumber }
								type="number"
							/>
						</div>
					</div>
				</Input>
			</tr>
		</Fragment>
	);
}

export default Number;
