/**
 * External dependencies
 */
import React, { Fragment } from 'react';

/**
 * Internal dependencies
 */
import {
	Instructions,
	Label,
	MaxLength,
	Name,
	Placeholder,
	Wrapper,
} from '../helpers/fields';

function Text( { name } ) {
	return (
		<Fragment>
			<Label name={ name } />
			<Name name={ name } />
			<Placeholder name={ name } />
			<Instructions name={ name } />
			<MaxLength name={ name } />
			<Wrapper name={ name } />
		</Fragment>
	);
}

export default Text;
