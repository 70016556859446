const LOAD = 'form-config-creator/form/LOAD';

const reducer = ( state = { data: null }, action ) => {
	switch ( action.type ) {
		case LOAD:
			return {
				data: action.data,
			};

		default:
			return state;
	}
};

export const load = data => ( { type: LOAD, data } );

export default reducer;
