/**
 * External dependencies
 */
import React from 'react';
import { Field } from 'redux-form';

/**
 * Internal dependencies
 */
import { Label, Input } from '../helpers/table';

function Infotext( { name } ) {
	return (
		<tr>
			<Label>Message</Label>
			<Input>
				<Field name={ `${ name }.content` } component="textarea" required rows="6" />
			</Input>
		</tr>
	);
}

export default Infotext;
