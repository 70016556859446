/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import { Label, Input } from '../helpers/table';

function Summary() {
	return (
		<tr>
			<Label />
			<Input>
				No options
			</Input>
		</tr>
	);
}

export default Summary;
