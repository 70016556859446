// @flow

/**
 * External dependencies
 */
import React from 'react';

/**
 * Intenral dependencies
 */
import TypeFields from './type-fields';

/**
 * Flow
 */
import type { FieldArrayProps } from 'redux-form';

function FormFields( { fields }: FieldArrayProps ) {
	function addField() {
		fields.push( { type: 'text' } );
	}

	const totalFields = fields.length;

	return (
		<div>
			<div className="fcc-fields">
				{ totalFields === 0 && (
					<div className="fcc-empty">
						Add fields by clicking the "Add field" button
					</div>
				) }

				{ fields.map( ( name, index ) => (
					<TypeFields
						key={ index }
						index={ index }
						name={ name }
						fields={ fields }
						total={ totalFields }
					/>
				) ) }
			</div>
			<div className="fcc-fields-footer">
				<button type="button" className="fcc-load-submit" onClick={ addField }>
					Add field
				</button>
			</div>
		</div>
	);
}

export default FormFields;
